<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="创建巡检"
                :visible.sync="visible"
                width="1450px"
            >
                <div class="create-modal-container">
                    <div class="overhaul-info-container">
                        <el-form ref="createForm" :inline="true" :model="formData" :rules="rules">
                            <el-form-item label="检修编号" :label-width="'80px'" style="marginRight: 130px;" prop="inspectionPlanCode">
                                <el-input disabled v-model="formData.inspectionPlanCode" placeholder="检修编号" :style="{width: '300px'}" :size="'small'"></el-input>
                            </el-form-item>
                            <el-form-item label="检修名称" :label-width="'80px'" style="marginRight: 130px;" prop="inspectionPlanName">
                                <el-input v-model="formData.inspectionPlanName" placeholder="检修名称" :style="{width: '300px'}" :size="'small'"></el-input>
                            </el-form-item>
                            <el-form-item label="执行班组" :label-width="'80px'" prop="inspectionTeamId"> 
                                <el-select v-model="formData.inspectionTeamId" :style="{width: '300px'}" :size="'small'">
                                    <el-option v-for="item in teamList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="周期" :label-width="'80px'" style="marginRight: 130px;" prop="inspectionCycleInterval">
                                <el-input v-model="formData.inspectionCycleInterval" placeholder="请输入数字" :style="{width: '188px', marginRight: '6px'}" :size="'small'"></el-input>
                                <el-select v-model="formData.inspectionCycleUnit" :style="{width: '106px'}" :size="'small'">
                                    <el-option label="天" :value="1"></el-option>
                                    <el-option label="周" :value="2"></el-option>
                                    <el-option label="月" :value="3"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="总次数" :label-width="'80px'" style="marginRight: 100px;" prop="inspectionTotalCount">
                                <el-input v-model="formData.inspectionTotalCount" placeholder="总次数" :style="{width: '300px'}" :size="'small'"></el-input>
                            </el-form-item>
                            <el-form-item label="开始执行日期" :label-width="'110px'" prop="inspectionBeginTime">
                                <el-date-picker
                                    type="date"
                                    placeholder="选择日期"
                                    :size="'small'"
                                    :style="{width: '300px'}"
                                    v-model="formData.inspectionBeginTime"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="检修备注" :label-width="'80px'" prop="inspectionRemark">
                                <el-input v-model="formData.inspectionRemark" placeholder="检修备注" :style="{width: '800px'}" :size="'small'"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="select-battery-container">
                        <div class="select-battery-tools">
                            <div class="tools-title">
                                选择检修电池
                            </div>
                            <div class="tools-form-container">
                                <el-form :inline="true" :model="queryForm">
                                    <el-form-item label="电池类型" :label-width="'80px'">
                                        <el-select v-model="queryForm.batteryType" :style="{width: '300px'}" :size="'small'">
                                            <el-option label="全部" :value="''"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="电池ID" :label-width="'80px'">
                                        <el-input v-model="queryForm.batteryCode" placeholder="电池ID" :style="{width: '300px'}" :size="'small'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="品牌" :label-width="'80px'">
                                        <el-input v-model="queryForm.batteryBranch" placeholder="品牌" :style="{width: '300px'}" :size="'small'"></el-input>
                                    </el-form-item>
                                    <el-form-item label="归属" :label-width="'80px'" style="marginRight: 90px;">
                                        <el-tree-select 
                                            v-model="queryForm.orgCode"
                                            ref="group-select-tree"
                                            class="group-select-tree" 
                                            :selectParams="selectParams" 
                                            :treeParams="treeParams" 
                                            style="width: 220px;"
                                        >
                                        </el-tree-select>
                                    </el-form-item>
                                    <el-form-item label="故障次数" :label-width="'80px'" style="marginRight: 100px;">
                                        <el-input v-model="queryForm.faultCount" placeholder="故障次数" :style="{width: '300px'}" :size="'small'"></el-input>
                                    </el-form-item>
                                    <el-form-item>
                                        <el-button type="primary" @click.stop="getBatteryList(1)">搜索</el-button>
                                        <el-button @click.stop="resetHandel">重置</el-button>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="battery-table-container">
                            <el-table 
                                height="calc(100% - 32px)" 
                                :data="batteryList"
                                @selection-change="handleSelectionChange"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55"
                                >
                                </el-table-column>
                                <el-table-column label="电池ID" prop="batteryCode" width="200px"></el-table-column>
                                <el-table-column label="电池类别" prop="batteryType"></el-table-column>
                                <el-table-column label="周期厂家" prop=""></el-table-column>
                                <el-table-column label="品牌" prop="batteryBrand"></el-table-column>
                                <el-table-column label="归属" prop="orgName"></el-table-column>
                                <el-table-column label="调入时间" prop="transferInTime" width="200px"></el-table-column>
                                <el-table-column label="故障次数" prop="faultCount"></el-table-column>
                                <el-table-column label="维修次数" prop="maintenanceCount"></el-table-column>
                                <el-table-column label="保养次数" prop="repairCount"></el-table-column>
                            </el-table>
                            <div class="table-page-container">
                                <el-pagination
                                    background
                                    :current-page="page"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios';
import './CreateModal.less';
import moment from 'moment';
export default {
    name: 'CreateModal',
    props: {
        getData: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            formData: {
                inspectionPlanCode: '',
                inspectionPlanName: '',
                inspectionTeamId: '',
                inspectionCycleInterval: '',
                inspectionCycleUnit: '',
                inspectionTotalCount: '',
                inspectionBeginTime: '',
                inspectionRemark: '',
                batteryCodes: []
            },
            teamList: [],
            queryForm: {
                batteryType: '',
                batteryCode: '',
                batteryBranch: '',
                orgCode: '',
                faultCount: ''
            },
            rules: {
                inspectionPlanCode: [
                    { required: true, message: '请填写检修编号', trigger: 'blur' },
                ],
                inspectionPlanName: [
                    { required: true, message: '请填写检修名称', trigger: 'blur' }
                ],
                inspectionTeamId: [
                    { required: true, message: '请选择班组', trigger: 'blur' }
                ],
                inspectionCycleInterval: [
                    { required: true, message: '请输入周期', trigger: 'blur' },
                    { validator: this.validateNumber, trigger: 'blur' }
                ],
                inspectionTotalCount: [
                    { required: true, message: '请输入总次数', trigger: 'blur' },
                    { validator: this.validateNumber, trigger: 'blur' }
                ],
                inspectionBeginTime: [
                    { required: true, message: '请选择开始时间', trigger: 'blur' },
                ],
                inspectionRemark: [
                    { required: true, message: '请输入备注', trigger: 'blur' },
                ]
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': false,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgCode'
                }
            },
            batteryList: [],
            total: 0,
            page: 1,
            pageSize: 10
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            if (flag) {
                this.getInspectionPlanCode();
                this.getOrgData();
                this.getBatteryList();
            }
        },
        getInspectionPlanCode() {
            axios.get('/api/inspectionPlan/getInspectionCode').then(rs => {
                this.formData.inspectionPlanCode = rs;
            }).catch(err => {
                this.$message.error(err);
            })
        },
        getTeamList() {
            axios.get('/api/gangerGroup/getList').then(rs => {
                this.teamList = rs;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        validateNumber(rule, value, callback) {
             if (/^[1-9]\d*$/.test(value)) {
                callback();
            } else {
                callback(new Error('请输入正确的周期'));
            }
        },
        async getOrgData() {
            const userinfo = localStorage.getItem('currentUser')
            const orgId = JSON.parse(userinfo).orgId;
            const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
            this.treeParams.data = [rs]
            this.$nextTick(() => {
                this.$refs['group-select-tree'].treeDataUpdateFun(this.treeParams.data);
            });
        },
        submitHandel() {
            this.$refs.createForm.validate(valid => {
                if (valid) {
                    const {
                        inspectionPlanCode,
                        inspectionPlanName,
                        inspectionTeamId,
                        inspectionCycleInterval,
                        inspectionCycleUnit,
                        inspectionTotalCount,
                        inspectionBeginTime,
                        inspectionRemark,
                        batteryCodes,
                    } = this.formData;
                    if (batteryCodes.length === 0) {
                        this.$message.info('请选择电池');
                        return;
                    }
                    const  _inspectionBeginTime = moment(inspectionBeginTime).format('YYYY-MM-DD HH:mm:ss');
                    axios.post('/api/inspectionPlan/create', {
                        inspectionPlanCode,
                        inspectionPlanName,
                        inspectionTeamId,
                        inspectionCycleInterval,
                        inspectionCycleUnit,
                        inspectionTotalCount,
                        inspectionBeginTime: _inspectionBeginTime,
                        inspectionRemark,
                        batteryCodes: batteryCodes.join(',')
                    }).then(rs => {
                        this.$message.success('创建成功');
                        this.visible = false;
                        this.getData();
                        this.$refs.createForm.resetFields();
                        this.formData.batteryCodes = [];
                        this.batteryList = [];
                    }).catch(err => {
                        this.$message.error(err);
                    });
                }
            });
        },
        getBatteryList(_page) {
            axios.get(`/api/battery/pageList/${_page === 1 ? 1 : this.page}/${this.pageSize}`, this.queryForm).then(rs => {
                this.batteryList = rs.records;
                this.total = rs.total;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        resetHandel() {
            this.queryForm = {
                batteryType: '',
                batteryCode: '',
                batteryBranch: '',
                orgCode: '',
                faultCount: 0
            };
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getBatteryList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getBatteryList();
        },
        handleSelectionChange(val) {
            this.formData.batteryCodes = val.map(item => item.batteryCode);
        },
    },
    mounted() {
        this.getTeamList();
    }
}
</script>