<template>
    <span @click.stop="changeVisible(true)">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                title="派发检修工单"
                :visible.sync="visible"
                width="600px"
            >
                <div class="dispatch-container">
                    <el-form 
                        label-width="100px" 
                        class="create-dispatch-form"
                        :model="formData"
                        :rules="rules"
                        ref="dispatchForm"
                    >
                        <el-form-item label="作业模板" :label-width="'150px'" prop="inspectionTemplateId">
                            <el-select v-model="formData.inspectionTemplateId" placeholder="作业模板" :style="{width: '300px'}" :size="'small'">
                                <el-option label="默认模块" :value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="运维员" :label-width="'150px'" prop="userId">
                            <el-select v-model="formData.userId" placeholder="运维员" :style="{width: '300px'}" :size="'small'">
                                <el-option v-for="item in operationPersonList" :key="item.id" :label="item.userName" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label-width="'150px'" label="上传模板" prop="fileUrl">
                            <el-upload
                                action="/api/file/upload"
                                multiple
                                :limit="1"
                                :headers="{ token: getToken() }"
                                :file-list="fileList"
                                :on-success="onSuccessHandel"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="visible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './DispatchModal.less';
import axios from '@/utils/axios'
export default {
    name: 'DispatchModal',
    props: {
        jobCode: {
            type: String,
            default: '',
        },
        getData: {
            type: Function,
            default: () => {}
        } 
    },
    data() {
        return {
            visible: false,
            formData: {
                inspectionTemplateId: '',
                userId: '',
                fileUrl: ''
            },
            operationPersonList: [],
            rules: {
                userId: [
                    { required: true, message: '请选择运维人员', trigger: 'blur' },
                ],
                inspectionTemplateId: [
                    { required: true, message: '请选择作业模板', trigger: 'blur' },
                ]
            },
            fileList: []
        }
    },
    methods: {
        changeVisible(flag) {
            this.visible = flag;
            if (flag) {
                this.getOperateUserList();
            }
        },
        submitHandel() {
            this.$refs.dispatchForm.validate(valid => {
                if (valid) {
                    axios.post('/api/job/allocationJob', { 
                        jobCode: this.jobCode,
                        ...this.formData
                    }).then(() => {
                        this.$message.success('派单成功');
                        this.visible = false;
                        this.fileList = [];
                        this.getData();
                    }).catch(err => {
                        this.$message.error(err);
                    });
                }
            });
        },
        getOperateUserList() {
            axios.get(`/api/operationPerson/pageList/${1}/${100}`, {}).then(rs => {
                this.operationPersonList = rs.records;
            });
        },
        getToken() {
			return localStorage.getItem('user-token');
		},
        onSuccessHandel(response) {
            this.formData.fileUrl = response.data.path;
        }
    }
}
</script>