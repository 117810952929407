<template>
    <div class="tools-container">
        <el-form :model="formData" :inline="true" class="demo-form-inline">
            <el-form-item label="检修编号" :label-width="'70px'">
                <el-input v-model="formData.inspectionCode" placeholder="检修编号" :style="{width: '220px'}" :size="'small'"></el-input>
            </el-form-item>
            <el-form-item label="检修名称" :label-width="'70px'">
                <el-input v-model="formData.inspectionName" placeholder="检修名称" :style="{width: '220px'}" :size="'small'"></el-input>
            </el-form-item>
            <el-form-item label="当前周期状态" :label-width="'100px'">
                <el-select v-model="formData.jobStatus" placeholder="当前周期状态" :style="{width: '220px'}" :size="'small'">
                    <el-option label="全部" :value="''"></el-option>
                    <el-option label="待分配" :value="10"></el-option>
                    <el-option label="待接单" :value="20"></el-option>
                    <el-option label="处理中" :value="30"></el-option>
                    <el-option label="待审核" :value="40"></el-option>
                    <el-option label="已完成" :value="50"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item style="float: right; marginRight: 0px;">
                <el-button type="primary" @click="getData">搜索</el-button>
                <el-button @click="resetHandel">重置</el-button>
            </el-form-item>
            <el-form-item label="电池编号" :label-width="'70px'">
                <el-input v-model="formData.batteryCodes" placeholder="电池编号" :style="{width: '220px'}" :size="'small'"></el-input>
            </el-form-item>
            <el-form-item label="检修日期" :label-width="'70px'">
                <el-date-picker
                    v-model="formData.time"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :size="'small'"
                >
                </el-date-picker>
            </el-form-item>
        </el-form>
        <div class="tool-button-container">
            <CreateModal :getData="getData">
                <el-button type="primary" size="small">新建</el-button>
            </CreateModal>
            <el-button type="primary" size="small" style="marginLeft: 15px;" @click="exportHandel">导出</el-button>
        </div>
    </div>
</template>

<script>
import axios from '@/utils/axios'
import CreateModal from './CreateModal';
import './Tools.less';
import moment from 'moment';
import { downloadFile } from '@/utils/commonFun';
export default {
    name: 'Tools',
    components: {
        CreateModal
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            formData: {
                batteryCodes: '',
                inspectionCode: '',
                inspectionName: '',
                jobStatus: '',
                time: '',
            },
            dialogVisible: false
        }
    },
    methods: {
        getData() {
            let time1 = '';
            let time2 = '';
            const {
                batteryCodes,
                inspectionCode,
                inspectionName,
                jobStatus,
                time,
            } = this.formData;
            const { page, pageSize } = this;
            if (time) {
                const [date1, date2] = time;
                time1 = moment(date1).format('YYYY-MM-DD HH:mm:ss');
                time2 = moment(date2).format('YYYY-MM-DD HH:mm:ss');
            }
            const url = `/api/inspectionCycleJob/pageList/${page}/${pageSize}`
            axios.get(url, { 
                batteryCodes,
                inspectionCode,
                inspectionName,
                jobStatus,
                time1,
                time2
            }).then(rs => {
                this.$emit('tableDataChange', rs);
            }).catch(err => {
                this.$message.error(err);
            });
        },
        resetHandel() {
            this.formData = {
                batteryCodes: '',
                inspectionCode: '',
                inspectionName: '',
                jobStatus: '',
                time: '',
            };
        },
        exportHandel() {
            let time1 = '';
            let time2 = '';
            const {
                batteryCodes,
                inspectionCode,
                inspectionName,
                jobStatus,
                time,
            } = this.formData;
            if (time) {
                const [date1, date2] = time;
                time1 = moment(date1).format('YYYY-MM-DD HH:mm:ss');
                time2 = moment(date2).format('YYYY-MM-DD HH:mm:ss');
            }
            let orgCode = '';
            const currentUserLocal = localStorage.getItem('currentUser');
            if (currentUserLocal) {
                orgCode = JSON.parse(currentUserLocal).org.orgCode;
            } 
            window.open(`/api/inspectionCycleJob/downExcel/1/100000?batteryCodes=${batteryCodes}&inspectionCode=${inspectionCode}&inspectionName=${inspectionName}&jobStatus=${jobStatus}&time1=${time1}&time2=${time2}&orgCode=${orgCode}`);
        }
    },
    mounted() {
        const { orderStatus } = this.$route.params;
        if (orderStatus) {
            this.formData.jobStatus = Number(orderStatus)
        }
        this.getData();
    }
}
</script>