var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"planned-overhaul-container"},[_c('div',{staticClass:"table-tools-container"},[_c('Tools',{ref:"tableTool",attrs:{"page":_vm.page,"pageSize":_vm.pageSize},on:{"tableDataChange":_vm.tableDataChangeHandel}})],1),_c('div',{staticClass:"table-content"},[_c('el-table',{attrs:{"height":"100%","data":_vm.list}},[_c('el-table-column',{attrs:{"label":"检修编号","prop":"inspectionPlanCode","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"inspectionPlanCode-span",attrs:{"title":row.inspectionPlanCode}},[_vm._v(_vm._s(row.inspectionPlanCode))])]}}])}),_c('el-table-column',{attrs:{"label":"检修名称","prop":"inspectionName","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"inspectionName-span",attrs:{"title":row.inspectionName}},[_vm._v(_vm._s(row.inspectionName))])]}}])}),_c('el-table-column',{attrs:{"prop":"batteryNum","label":"电池数量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"bottom","width":"120","trigger":"hover"}},[_c('div',{staticClass:"battery-detail-content"},_vm._l((scope.row.batteryCodes.split(',')),function(item){return _c('div',{key:item,staticClass:"batter-no-item"},[_vm._v(_vm._s(item))])}),0),_c('span',{staticClass:"battery-number-span",attrs:{"slot":"reference"},slot:"reference"},[_vm._v(_vm._s(scope.row.batteryNum))])])]}}])}),_c('el-table-column',{attrs:{"label":"当前周期","prop":"cycleNum"}}),_c('el-table-column',{attrs:{"label":"总次数","prop":"totalCount"}}),_c('el-table-column',{attrs:{"label":"开始执行时间","prop":"planBeginTime","width":"180"}}),_c('el-table-column',{attrs:{"label":"当前工单状态","prop":"jobStatusLable"}}),_c('el-table-column',{attrs:{"label":"执行人","prop":"inspentionUserName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.inspentionUserName ? row.inspentionUserName : '-'))])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"operate-a",on:{"click":function($event){return _vm.jumpDetail(row.jobCode)}}},[_vm._v("查看")]),_c('DispatchModal',{attrs:{"jobCode":row.jobCode,"getData":_vm.getTableData}},[(row.jobStatus === 10)?_c('a',{staticClass:"operate-a"},[_vm._v("派单")]):_vm._e()])]}}])})],1)],1),_c('div',{staticClass:"table-page"},[_c('div',{staticClass:"table-page-container"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page,"page-sizes":[10, 20, 30, 40],"page-size":_vm.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }