<template>
    <div class="planned-overhaul-container">
        <div class="table-tools-container">
            <Tools ref="tableTool" :page="page" :pageSize="pageSize" @tableDataChange="tableDataChangeHandel"/>   
        </div>
        <div class="table-content">
            <el-table height="100%" :data="list">
                <el-table-column label="检修编号" prop="inspectionPlanCode" width="150">
                    <template slot-scope="{row}">
                        <span class="inspectionPlanCode-span" :title="row.inspectionPlanCode">{{row.inspectionPlanCode}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="检修名称" prop="inspectionName" width="230">
                    <template slot-scope="{row}">
                        <span class="inspectionName-span" :title="row.inspectionName">{{row.inspectionName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="batteryNum" label="电池数量">
                    <template v-slot="scope">
                        <el-popover
                            placement="bottom"
                            width="120"
                            trigger="hover"
                        >
                            <div class="battery-detail-content">
                                <div v-for="item in scope.row.batteryCodes.split(',')" :key="item" class="batter-no-item">{{ item }}</div>
                            </div>
                            <span slot="reference" class="battery-number-span">{{ scope.row.batteryNum }}</span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="当前周期" prop="cycleNum"></el-table-column>
                <el-table-column label="总次数" prop="totalCount"></el-table-column>
                <el-table-column label="开始执行时间" prop="planBeginTime" width="180"></el-table-column>
                <el-table-column label="当前工单状态" prop="jobStatusLable"></el-table-column>
                <el-table-column label="执行人" prop="inspentionUserName">
                    <template slot-scope="{row}">
                        <span>{{ row.inspentionUserName ? row.inspentionUserName : '-' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="{row}">
                        <a class="operate-a" @click="jumpDetail(row.jobCode)">查看</a>
                        <DispatchModal :jobCode="row.jobCode" :getData="getTableData">
                            <a v-if="row.jobStatus === 10" class="operate-a">派单</a>
                        </DispatchModal>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table-page">
            <div class="table-page-container">
                <el-pagination
                    background
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import './Index.less'
import Tools from './components/Tools'
import DispatchModal from './components/DispatchModal'
export default {
    name: 'PlannedOverhaul',
    components: {
        Tools,
        DispatchModal
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            list: [],
            total: 0
        }
    },
    methods: {
        jumpDetail(jobCode) {
            this.$router.push({
                name: 'OrderDetail',
                params: {
                    jobCode
                }
            });
        },
        tableDataChangeHandel(rs) {
            const { 
                records,
                total
            } = rs;
            this.list = records;
            this.total = total;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.$nextTick(() => {
                this.getTableData();
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$nextTick(() => {
                this.getTableData();
            });
        },
        getTableData() {
            this.$refs['tableTool'].getData();
        },
    }
}
</script>